<template>
  <div class="pes_page">
    <img :src="require('../../assets/img/pes/banner.png')" alt="" />
    <!-- 学生报告 -->
    <div class="stu_report">
      <div class="tit">学生报告</div>
      <div class="cont">
        <div class="col-1">
          <button
            class="btn tab"
            :class="{ btn_active: stu.grade == 2 }"
            @click="getStuClassList(2)"
          >
            小班
          </button>
          <button
            class="btn tab"
            :class="{ btn_active: stu.grade == 1 }"
            @click="getStuClassList(1)"
          >
            中班
          </button>
          <button
            class="btn tab"
            :class="{ btn_active: stu.grade == 0 }"
            @click="getStuClassList(0)"
          >
            大班
          </button>
        </div>
        <div class="col-2">
          <button
            v-show="stu.stuClassList.length"
            class="btn tab"
            :class="{ btn_active: stu.stuClass == item.name }"
            v-for="item in stu.stuClassList"
            :key="item.id"
            @click="getStuList(item)"
            :title="item.name"
          >
            {{ item.name }}
          </button>
          <div v-show="!stu.stuClassList.length">暂无数据</div>
        </div>
        <div class="col-3">
          <div
            v-show="stu.stuList.length"
            class="link tab txt-len"
            :class="{ link_active: stu.stu == item.name }"
            v-for="item in stu.stuList"
            :key="item.id"
            @click="getStuReport(item)"
            :title="item.name"
          >
            {{ item.name }}
          </div>
          <div v-show="!stu.stuList.length">暂无数据</div>
        </div>
        <div class="col-4">
          <div
            v-show="stu.stuReportList.length"
            class="link tab"
            v-for="item in stu.stuReportList"
            :key="item.testingLog.id"
            @click="tabStuReport(item)"
          >
            {{
              item.semesterName +
              (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告")
            }}
          </div>
          <div v-show="!stu.stuReportList.length">暂无数据</div>
        </div>
      </div>
    </div>

    <!-- 班级报告 -->
    <div class="stu_report class_report">
      <div class="tit">班级报告</div>
      <div class="cont">
        <div class="col-1">
          <button
            class="btn tab"
            :class="{ btn_active: classInfo.grade == 2 }"
            @click="gradeList(2)"
          >
            小班
          </button>
          <button
            class="btn tab"
            :class="{ btn_active: classInfo.grade == 1 }"
            @click="gradeList(1)"
          >
            中班
          </button>
          <button
            class="btn tab"
            :class="{ btn_active: classInfo.grade == 0 }"
            @click="gradeList(0)"
          >
            大班
          </button>
        </div>
        <div class="col-2">
          <button
            v-show="classInfo.classList.length"
            class="btn tab"
            :class="{ btn_active: classInfo.class == item.name }"
            v-for="item in classInfo.classList"
            :key="item.id"
            @click="getClassReport(item)"
            :title="item.name"
          >
            {{ item.name }}
          </button>
          <div v-show="!classInfo.classList.length">暂无数据</div>
        </div>
        <div class="col-4">
          <div
            v-show="classInfo.classReportList.length"
            class="link tab"
            v-for="item in classInfo.classReportList"
            :key="item.testingLog.id"
            @click="tabClassReport(item)"
          >
            {{
              item.semesterName +
              (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告")
            }}
          </div>
          <div v-show="!classInfo.classReportList.length">暂无数据</div>
        </div>
      </div>
    </div>

    <!-- 幼儿园报告 -->
    <div class="yreport" v-loading="loading">
      <div class="tit">幼儿园报告</div>
      <div v-if="school.report.length != 0" class="list">
        <div
          class="item tab"
          v-for="item in school.report"
          @click="toSchoolReport(item)"
          :key="item.testingLog.id"
        >
          {{
            item.semesterName +
            (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告")
          }}
        </div>
      </div>
      <div v-else class="empty_data">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pes",
  components: {},
  data() {
    return {
      // 学生报告
      stu: {
        stuClassList: [],
        stuList: [],
        stuReportList: [],
        grade: "",
        stuClass: "",
        stu: "",
      },
      classInfo: {
        classList: [],
        classReportList: [],
        class: "",
        grade: "",
      },
      // 结果
      chengji: [],
      school: {
        report: [],
      },
      loading: true,
      cacheStuId:'',
    };
  },
  created() {},
  mounted() {
    this.getStuClassList(2);
    this.gradeList(2);
    this.getSchoolReport();
  },
  methods: {
    // 大中小 0 1 2
    // 学生报告初始化
    async getclassList(type) {
      // 班级列表
      let res = await this.api.pes.findByGradeType({
        schoolId: this.$store.state.userInfo.schoolId,
        type: type,
      });
      return res;
    },
    async getStuClassList(type) {
      let res = await this.getclassList(type);
      this.stu.grade = type;
      this.stu.stuClassList = res.data;
      this.stu.stuClass = (res.data[0] && res.data[0].name) || "";

      if (!res.data.length) {
        this.stu.stuList = [];
        this.stu.stu = "";
        this.stu.stuReportList = [];
      } else {
        //学生列表
        await this.getStuList(res.data[0]);
      }
    },
    // 点击班级获取报告
    async getStuList(item) {
      //学生列表
      this.stu.stuClass = item.name;
      let res = await this.api.pes.findByGradeId({ gradeId: item.id });
      this.stu.stuList = res.data;
      this.stu.stu = (res.data[0] && res.data[0].name) || "";

      if (!res.data.length) {
        this.stu.stuReportList = [];
      } else {
        // 报告列表
        await this.getStuReport(res.data[0]);
      }
    },
    // 点击学生获取报告列表
    async getStuReport(item) {
      this.stu.stu = item.name;
      this.cacheStuId = item.id;
      let res = await this.api.pes.findByStuId({
        schoolId: this.$store.state.userInfo.schoolId,
        stuId: item.id,
      });
      this.stu.stuReportList = res.data;
    },

    // 班级报告初始化
    async gradeList(type) {
      // 获取班级列表
      let res = await this.getclassList(type);
      this.classInfo.grade = type;
      this.classInfo.classList = res.data;
      this.classInfo.class = (res.data[0] && res.data[0].name) || "";
      // 获取班级报告
      this.getClassReport(res.data[0]);
    },

    // 点击班级获取报告
    async getClassReport(item) {
      this.classInfo.class = item.name;
      let res = await this.api.pes.getGradeReportList({
        schoolId: this.$store.state.userInfo.schoolId,
        gradeId: item.id,
      });
      this.classInfo.classReportList = res.data;
    },
    async getSchoolReport() {
      let res = await this.api.pes.findBySchoolId({
        schoolId: this.$store.state.userInfo.schoolId,
      });
      this.loading = false;
      this.school.report = res.data;
    },
    // 点击班级报告跳转
    tabClassReport(item) {
      let data = {
        gradeId: item.testingLog.gradeId,
        isEnd: item.testingLog.isEnd,
        semesterId: item.testingLog.semesterId,
      };
      console.log(item);
      console.log("data1", data);
      this.api.pes.findReportByGradeId(data).then((res) => {
        console.log("运动测试", res);
        this.chengji = res.data;
        if (res.flag == true) {
          this.$router.push({
            path: "admin/report1",
            query: {
              class: this.classInfo.class,
              grade:
                this.classInfo.grade === 0
                  ? "大班"
                  : this.classInfo.grade === 1
                  ? "中班"
                  : "小班",
              classReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              gradeId: item.testingLog.gradeId,
              semesterId: item.testingLog.semesterId,
              type: item.testingLog.type,
              data: this.chengji,
            },
          });
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 点击学生报告跳转
    tabStuReport(item) {
      let data = {
        stuId: this.cacheStuId,
      };

      this.api.pes.findReportAuthByStuId(data).then((res) => {
        if (res.flag == true) {
          this.$router.push({
            path: "admin/report2",
            query: {
              class: this.stu.stuClass,
              grade:
                this.stu.grade === 0
                  ? "大班"
                  : this.stu.grade === 1
                  ? "中班"
                  : "小班",
              stuReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              stuId: item.testingLog.stuId,
              semesterId: item.testingLog.semesterId,
              type: item.testingLog.type,
              stu: this.stu.stu,
              gradeType: this.stu.grade,
            },
          });
        } else{
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    //点击园所报告跳转
    toSchoolReport(item) {
      this.loading = true;
      let data = {
        semesterId: item.testingLog.semesterId,
        schoolId: item.testingLog.schoolId,
        isEnd: item.testingLog.isEnd,
      };
      this.api.pes.findSchoolTestPercent(data).then((res) => {
        this.loading = false;
        if (res.flag) {
          this.$router.push({
            path: "admin/report3",
            query: {
              schoolReport:
                item.semesterName +
                (item.testingLog.isEnd === "0" ? "初测试报告" : "末测试报告"),
              isEnd: item.testingLog.isEnd,
              semesterId: item.testingLog.semesterId,
              schoolId: item.testingLog.schoolId,
            },
          });
        } else {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pes_page {
  width: 80%;
  margin: 100px auto 0;

  img {
    width: 100%;
    height: auto;
    padding-bottom: 80px;
  }

  .stu_report {
    min-height: 620px;
    margin: 80px 0;
    padding: 60px 40px;
    @include bg("../../assets/img/pes/jf.png");
    position: relative;

    .tit {
      width: 230px;
      height: 54px;
      position: absolute;
      left: 0;
      top: -30px;
      border: solid 0.3px rgb(255, 255, 255);
      border-radius: 10px;
      background-color: rgb(40, 74, 160);
      line-height: 54px;
      font-size: 34px;
      color: #fff;
      text-align: center;
    }

    .cont {
      background: #fff;
      height: 625px;
      display: flex;

      .col-1 {
        width: 130px;
        border-right: solid 1px #eee;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 40px;

        .btn {
          border-radius: 0.1rem;
          width: 1.3rem;
          height: 0.54rem;
          font-size: 0.24rem;
          
        }
      }

      .col-2 {
        width: 300px;
        border-right: solid 1px #eee;
        padding: 40px 20px;

        .btn {
          width: 125px;
          height: 40px;
          font-size: 22px;
          margin: 0px 20px 30px 0;
          float: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .col-3 {
        width: 280px;
        border-right: solid 1px #eee;
        padding: 40px;
        overflow-y: auto;
        .link_active {
          color: #cb1b31;
          text-decoration: underline;
        }
      }

      .col-4 {
        flex: 1;
        padding: 40px;
        overflow-y: scroll;
        box-sizing: border-box;

        .link {
          display: block;
          margin-bottom: 20px;
          font-size: 20px;
          line-height: 20px;
          text-align: left;
          float: none;
          transition: all 0.3s;
        }

        .link:active {
          color: #cb1b31;
          text-decoration: underline;
        }

        .link:hover {
          color: #cb1b31;
          text-decoration: underline;
        }
      }

      .btn {
        display: block;
        background-color: #cb1b31;
        border: none;
        box-sizing: border-box;
        color: #fff;
        outline: none;
      }

      .link {
        width: 100px;
        margin: 0px 20px 30px 0;
        float: left;
        line-height: 20px;
        font-size: 20px;
        text-align: left;
        white-space: nowrap;
      }
      .txt-len{
        text-overflow: ellipsis;
        overflow:hidden
      }

      .link:hover {
        color: #cb1b31;
        text-decoration: underline;
      }

      .btn_active {
        background-color: #284aa0 !important;
      }
    }
  }

  .class_report {
    @include bg("../../assets/img/pes/bg.png");
  }

  .yreport {
    margin: 60px 0;

    .tit {
      height: 70px;
      background: #cb1b31;
      font-size: 34px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 10px;
      line-height: 70px;
      color: #fff;
    }

    .list {
      border: solid 1px #eee;
      margin: 10px 0;
      padding: 10px;
      text-align: center;
      @include flex(row, space-between, center);
      flex-wrap: wrap;

      .item {
        width: 50%;
        margin: 15px 0;
        font-size: 24px;
        line-height: 24px;
        color: #333;
      }

      .item:hover {
        color: #777;
        text-decoration: underline;
      }
    }
    .empty_data {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: 20px;
      font-size: 24px;
      line-height: 24px;
      color: #333;
    }
  }
}
</style>